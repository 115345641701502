
import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"

import {Row, Tag, Button, Card, Divider, Timeline, Col} from 'antd';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
export const query = graphql`
    query {
        directus {
            trainers {
                name
                functions
                bio
                image{
                    id
                    imageFile{
                        childImageSharp{
                            fluid(maxWidth:2000){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }

    }
`

const getTitle = function (item) {
    return <>
        {item.name} &nbsp;

        </>;
}
const Team = ({data}) => (
    <Container defKey="2">
        <SEO title="Das Team"/>
        <div style={{padding: "1rem"}}>
            <h1>Das Team</h1>
        Aktuell besteht das Team aus den folgenden Personen:
        <br/>
            {data.directus.trainers.map((item) => {
                return <Card style={{margin:"1rem"}}>
                     <Row >
                        <Col md={12} xs={24}>
                            <Img fadeIn fluid={item.image.imageFile.childImageSharp.fluid}/>
                        </Col>
                        <Col style={{padding:20}} md={12} xs={24}>
                            <h2>{getTitle(item)}</h2>
                            <Row>
                                <Col xs={24}>   {item.functions.map((i)=>{
                                    return <Tag color={"default"}>{i}</Tag>
                                })}</Col>
                            </Row>

                        </Col>
                    </Row>
                </Card>
            })}
        </div>
    </Container>
)

export default Team

